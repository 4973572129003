
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fortawesome/fontawesome-free/css/all.css';
import 'vuetify/dist/vuetify.min.css';
import '../assets/css/style.sass';


Vue.use(Vuetify);

const opts = {
  customVariables: ['~/assets/css/style.sass'],
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      light: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#F5333F',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
};

export default new Vuetify(opts);
