import userService from '../../firebase/firestore/users';

const { errorMsg } = require('../../static/errorMessage');

const defaultState = {
  profile: undefined,
  isProfileReady: undefined,
};

const actions = {
  getProfile: ({ commit }, { uid }) => new Promise((resolve, reject) => {
    userService.getUser({ uid })
      .then((doc) => {
        const data = doc.data();
        commit('setProfile', data);
        // const isEmpty = Object.values(data).some(x => x === null);
        let isEmpty = true;
        if (data && data.fth && data.lth && data.fen && data.len
          && data.dob && data.sex && data.occupation && data.position
          && data.degree && data.workAge && data.salary && data.tel
          && data.lineId && data.contactEmail && data.address
          && data.subdistrict && data.district && data.province
          && data.postalCode && data.facebookEmail) {
          isEmpty = false;
        }
        if (isEmpty) {
          resolve(commit('setIsProfileReady', false));
        } else {
          resolve(commit('setIsProfileReady', true));
        }
      })
      .catch(() => {
        reject(commit('setError', errorMsg['connection/unavailable']));
      });
  }),
  setProfile: ({ commit }, { uid, data }) => new Promise((resolve, reject) => {
    userService.setUser({ uid, data })
      .then(() => {
        userService.getUser({ uid })
          .then((doc) => {
            resolve(commit('setProfile', doc.data()));
          })
          .catch(() => {
            reject(commit('setError', errorMsg['connection/unavailable']));
          });
      });
  }),
};

const mutations = {
  setProfile(state, payload) {
    state.profile = payload;
  },
  setIsProfileReady(state, payload) {
    state.isProfileReady = payload;
  },
};

const getters = {
  profile: state => state.profile,
  isProfileReady: state => state.isProfileReady,
};

export default {
  state: defaultState,
  actions,
  mutations,
  getters,
};
