<template>
  <div>
    <v-app-bar dense fixed app>
      <router-link to="/">
        <v-img
        :src="require('@/assets/image/achieveplus-logo.svg')"
        contain
        width="146"
        />
      </router-link>

      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          v-for="item in menu"
          :key="item.title"
          :to="item.link"
          text
          tag="caption"
        >
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="hidden-md-and-up"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="item in menu"
            :key="item.title"
            :to="item.link">
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <LoginPopup v-if="!isHidden" />
    </v-app-bar>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      multi-line
      bottom
      color="error"
    >
      {{ error }}
      <v-btn
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import LoginPopup from '@/components/LoginPopup.vue';

export default {
  components: {
    LoginPopup,
  },
  data() {
    return {
      menu: [
        { title: 'HOME', link: '/' },
        { title: 'MEMBER', link: '/member' },
        { title: 'TEST', link: '/survey' },
        { title: 'STORE', link: '/store' },
        { title: 'ABOUT US', link: '/about' },
      ],
      isHidden: false,
      snackbar: false,
      isMember: false,
      error: '',
      timeout: 0,
    };
  },
  mounted() {
    const { isAuthenticated } = this.$store.getters;
    this.isHidden = isAuthenticated || false;
    if (this.isHidden) {
      this.menu = [
        { title: 'HOME', link: '/' },
        { title: 'STUDIO', link: '/studio' },
        { title: 'MEMBER', link: this.isMember ? '/profile/member' : '/member' },
        { title: 'TEST', link: '/survey' },
        { title: 'STORE', link: '/store' },
        { title: 'ABOUT US', link: '/about' },
        { title: 'PROFILE', link: '/profile' },
        { title: 'LOGOUT', link: '/logout' },
      ];
    } else {
      this.menu = [
        { title: 'HOME', link: '/' },
        { title: 'MEMBER', link: this.isMember ? '/profile/member' : '/member' },
        { title: 'TEST', link: '/survey' },
        { title: 'STORE', link: '/store' },
        { title: 'ABOUT US', link: '/about' },
      ];
    }
  },
  computed: {
    isAuthen() {
      return this.$store.getters.isAuthenticated;
    },
    getError() {
      return this.$store.getters.error;
    },
    getProfile() {
      return this.$store.getters.profile;
    },
  },
  watch: {
    isAuthen(val) {
      if (val) {
        this.isHidden = true;
        this.menu = [
          { title: 'HOME', link: '/' },
          { title: 'STUDIO', link: '/studio' },
          { title: 'MEMBER', link: '/member' },
          { title: 'TEST', link: '/survey' },
          { title: 'STORE', link: '/store' },
          { title: 'ABOUT US', link: '/about' },
          { title: 'PROFILE', link: '/profile' },
          { title: 'LOGOUT', link: '/logout' },
        ];
      } else {
        this.isHidden = false;
        this.menu = [
          { title: 'HOME', link: '/' },
          { title: 'MEMBER', link: '/member' },
          { title: 'TEST', link: '/survey' },
          { title: 'STORE', link: '/store' },
          { title: 'ABOUT US', link: '/about' },
        ];
      }
    },
    getError(error) {
      if (error) {
        this.error = this.$store.getters.error;
        this.$store.dispatch('clearError');
        this.snackbar = true;
      }
    },
    getProfile(val) {
      if (val.isMember && val.PDPA) {
        this.isMember = true;
        this.menu = [
          { title: 'HOME', link: '/' },
          { title: 'STUDIO', link: '/studio' },
          { title: 'MEMBER', link: '/profile/member' },
          { title: 'TEST', link: '/survey' },
          { title: 'STORE', link: '/store' },
          { title: 'ABOUT US', link: '/about' },
          { title: 'PROFILE', link: '/profile' },
          { title: 'LOGOUT', link: '/logout' },
        ];
      } else {
        this.isMember = false;
        this.menu = [
          { title: 'HOME', link: '/' },
          { title: 'STUDIO', link: '/studio' },
          { title: 'MEMBER', link: '/member' },
          { title: 'TEST', link: '/survey' },
          { title: 'STORE', link: '/store' },
          { title: 'ABOUT US', link: '/about' },
          { title: 'PROFILE', link: '/profile' },
          { title: 'LOGOUT', link: '/logout' },
        ];
      }
    },
  },
};
</script>

<style lang="css" scoped>
.v-dialog{
  box-shadow: unset;
  -webkit-box-shadow: unset;
}
.v-dialog__content{
  box-shadow: unset;
  -webkit-box-shadow: unset;
}
.pd-24px{
  padding-left: 24px;
  margin-bottom: -24px;
}
</style>
