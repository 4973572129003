const errorMsg = {
  'auth/email-already-in-use': '[101] The email address is already in use by another account.',
  'auth/user-not-found': '[102] The username doesn’t exist.',
  'auth/wrong-password': '[103] Incorrect username or password. Please try again.',
  'connection/unavailable': '[201] Connection Lost ! Check your network connection and try again.',
  'cuma/error': '[301] Cannot retrieve your account from Chula Mooc Achieve.',
  'cuma/linked': '[302] This username was already linked.',
  'cuma/never': '[303] The account you linked has never been enrolled yet in Chula Mooc Achieve.',
  'other/fallback': '[401] Somethings went wrong, please try again.',
  'req/login': '[601] Please login before continue.',
  'req/purchased': '[602] Sorry, you have never purchased any product.',
  'req/text-field': '[603] Please fill in the required field(s).',
  'store/bundle-purchased-other': '[701] You have already purchased one or more items from this bundle.',
  'store/bundle-purchased-pathway': '[702] You have already purchased this pathway or bundle.',
  'store/closed': '[703] This product cannot be purchased anymore.',
  'store/full': '[704] This product is full.',
  'store/not-found': '[705] Oops! This product doesn’t exist.',
  'store/not-open': '[706] This product is not yet opened for purchase.',
  'store/purchased': '[707] You have already purchased this product.',
};

const getErrorMsg = (str, erId) => {
  const errMsg = {
    'pro/cannot': `[501] Sorry, Code ${str} cannot be used with this product.`,
    'pro/exp': `[502] Sorry, Code ${str} is expired.`,
    'pro/not-found': `[503] Sorry, Code ${str} is invalid`,
  };
  return errMsg[erId];
};

module.exports = {
  errorMsg,
  getErrorMsg,
};
