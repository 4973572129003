const defaultState = {
  showedCk: false,
  showedAn: false,
};

const actions = {
  setShowedCk({ commit }, payload) {
    commit('setShowedCk', payload);
  },
  setShowedAn({ commit }, payload) {
    commit('setShowedAn', payload);
  },
};

const mutations = {
  setShowedCk(state, payload) {
    state.showedCk = payload;
  },
  setShowedAn(state, payload) {
    state.showedAn = payload;
  },
};

const getters = {
  showedCk: state => state.showedCk,
  showedAn: state => state.showedAn,
};

export default {
  state: defaultState,
  actions,
  mutations,
  getters,
};
