import axios from 'axios';
import qs from 'qs';

const login = async ({ username, password }) => {
  const urlService = process.env.VUE_APP_CUMA_API;
  const clientId = process.env.VUE_APP_CLIENT_ID;
  const clientSecret = process.env.VUE_APP_CLIENT_SECRET;
  const option = {
    method: 'POST',
    url: `${urlService}/auth/login`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify({
      username,
      password,
      grant_type: 'password',
      client_id: clientId,
      client_secret: clientSecret,
    }),
  };
  try {
    return await axios(option);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const haveEnroll = async ({ token }) => {
  const urlService = process.env.VUE_APP_CUMA_API;
  const option = {
    method: 'GET',
    url: `${urlService}/playground/enroll/have-enroll`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    return await axios(option);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default {
  login,
  haveEnroll,
};
