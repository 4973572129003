import firebase from '../index';

const setting = firebase.firestore().collection('setting');
const fbTime = () => firebase.firestore.Timestamp.now();

const acConfig = async () => {
  const snapshot = await setting.doc('achieveChallenge').get();
  return snapshot.data();
};

const annConfig = async () => {
  const snapshot = await setting.doc('announcement').get();
  return snapshot.data();
};

export default {
  acConfig,
  annConfig,
  fbTime,
};
