<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{  }">
      <v-btn color="error" class="button" @click="flagDialog(!dialog)">SIGN IN</v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="flagDialog(false)">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="isLogin">Sign in</v-toolbar-title>
        <v-toolbar-title v-else-if="!isLogin">Sign up</v-toolbar-title>
      </v-toolbar>
      <v-container class="pa-5">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="12">
            <v-card flat>
              <v-card-text v-if="isLogin">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    name="email"
                    label="EMAIL"
                    type="email"
                    v-model="email"
                    :rules="emailRules"
                    required>
                  </v-text-field>
                  <v-text-field
                    name="password"
                    label="PASSWORD"
                    id="password" type="password"
                    required v-model="password"
                    :rules="passwordRules">
                  </v-text-field>
                </v-form>
                <v-row justify="end" class="mr-0">
                  <ResetPassword
                    text="Forgot your password"
                    :forgotemail="email"
                  />
                </v-row>
              </v-card-text>
              <v-card-text v-else-if="!isLogin">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    name="email"
                    label="EMAIL"
                    type="email"
                    v-model="email"
                    :rules="emailRules"
                    required>
                  </v-text-field>
                  <v-text-field
                    name="password"
                    label="PASSWORD"
                    id="password" type="password"
                    required v-model="password"
                    :rules="passwordRules">
                  </v-text-field>
                  <v-text-field
                    name="confirm password"
                    label="CONFIRM PASSWORD"
                    id="confirmPassword" type="password"
                    required v-model="confirmPassword"
                    :rules="confirmPasswordRules">
                  </v-text-field>
                </v-form>
              </v-card-text>
              <v-col class="pa-0">
                <v-card-actions>
                  <v-btn
                    v-if="isLogin"
                    class="social-login-btn"
                    color="#F5333F"
                    :disabled="!valid"
                    @click="submit">
                    SIGN IN
                  </v-btn>
                  <v-btn
                    v-else-if="!isLogin"
                    class="social-login-btn"
                    color="#F5333F"
                    :disabled="!valid"
                    @click="signup">
                    SIGN UP
                  </v-btn>
                </v-card-actions>
              </v-col>
              <v-row
                justify="center"
                tag="caption"
                class="text-xs-center base-text mt-3">
                <span v-if="isLogin">
                  Don’t have an account?
                  <a v-on:click="isLogin = !isLogin" class="underline">Sign up</a>
                </span>
                <span v-else-if="!isLogin">
                  Already have an account?
                  <a v-on:click="isLogin = !isLogin" class="underline">Sign in</a>
                </span>
                <span class="pl-1">here</span>
              </v-row>
              <v-container><v-divider></v-divider></v-container>
              <v-col cols="auto" class="pa-0">
                <v-row justify="center" class="ma-2">
                  <v-btn color="#314992" class="social-login-btn" @click="facebookMethod">
                    <v-row justify="center" align="center">
                      <v-icon left class="font-weight-400">fab fa-facebook-square</v-icon>
                      Login With Facebook
                    </v-row>
                  </v-btn>
                </v-row>
                <v-row justify="center" class="ma-2">
                  <v-btn color="#DD4B39" class="social-login-btn" @click="googleMethod">
                    <v-row justify="center" align="center">
                      <v-icon left class="font-weight-400">fab fa-google</v-icon>
                      Login With Google
                    </v-row>
                  </v-btn>
                </v-row>
                <v-row justify="center" class=" ma-2">
                  <v-btn color="#00A4EF" class="social-login-btn" @click="microsoftMethod">
                    <v-row justify="center" align="center">
                      <v-icon left class="font-weight-400">fab fa-microsoft</v-icon>
                      Login With Microsoft
                    </v-row>
                  </v-btn>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ResetPassword from '@/components/ResetPassword.vue';

export default {
  name: 'LoginPopup',
  components: {
    ResetPassword,
  },
  props: ['to'],
  data() {
    return {
      isLogin: true,
      dialog: false,
      valid: false,
      email: '',
      password: '',
      confirmPassword: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => v.length >= 6
                    || 'Password must be greater than 6 characters',
      ],
      confirmPasswordRules: [
        v => !!v || 'Confirm password is required',
        v => v === this.password
                    || 'Confirm password must be same as Password',
      ],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('setLoading', true);
        this.$store.dispatch('login', {
          email: this.email,
          password: this.password,
        })
          .then(() => {
            this.$store.dispatch('setLoading', false);
            this.flagDialog(false);
          });
      }
    },
    signup() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('setLoading', true);
        this.$store.dispatch('signup', {
          email: this.email,
          password: this.password,
        })
          .then(() => {
            this.$store.dispatch('setLoading', false);
          });
      }
    },
    googleMethod() {
      this.$store.dispatch('setLoading', true);
      this.$store.dispatch('googleLogin')
        .then(() => {
          this.$store.dispatch('setLoading', false);
          this.flagDialog(false);
        });
    },
    facebookMethod() {
      this.$store.dispatch('setLoading', true);
      this.$store.dispatch('facebookLogin')
        .then(() => {
          this.$store.dispatch('setLoading', false);
          this.flagDialog(false);
        });
    },
    microsoftMethod() {
      this.$store.dispatch('setLoading', true);
      this.$store.dispatch('microsoftLogin')
        .then(() => {
          this.$store.dispatch('setLoading', false);
          this.flagDialog(false);
        });
    },
    flagDialog(bool) {
      this.$store.dispatch('setPopLogin', bool);
    },
  },
  computed: {
    isAuthen() {
      return this.$store.getters.isAuthenticated;
    },
    isPop() {
      return this.$store.getters.isPop;
    },
  },
  watch: {
    isAuthen(bool) {
      if (bool) {
        this.dialog = false;
      }
    },
    isPop(bool) {
      this.dialog = bool;
    },
  },
};
</script>

<style lang="sass" scoped>

  $white-color: #ffffff

  .social-login-btn
    color: $white-color
    width: 80%
    margin-left: auto
    margin-right: auto
    text-align: center

  .flex
    display: flex
    justify-content: center

  .social-icon
    margin-left: 10px
    margin-right: 10px
    margin-top: auto
    margin-button: auto

  .v-btn__content
    font-weight: 700

  @media screen and (max-width: 480px)
    .social-login-btn
      width: 100%
  @media screen and (max-width: 320px)
    .social-login-btn
      width: 90%
</style>
