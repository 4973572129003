import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import authenticate from './modules/authenticate';
import profiler from './modules/profiler';
import shared from './modules/shared';
import shareLocal from './modules/shareLocal';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    authenticate: state.authenticate,
    shareLocal: state.shareLocal,
  }),
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authenticate,
    profiler,
    shared,
    shareLocal,
  },
  plugins: [vuexLocal.plugin],
});
