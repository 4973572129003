import firebase from '../index';

const users = firebase.firestore().collection('users');
const memberLvl = firebase.firestore().collection('memberLevels');

const setUser = ({ uid, data }) => users.doc(uid)
  .set(data, { merge: true });

const getUser = ({ uid }) => users.doc(uid).get();

const getMemberDiscount = ({ lvl }) => memberLvl.doc(lvl).get()
  .then(doc => doc.data());

export default {
  setUser,
  getUser,
  getMemberDiscount,
};
