/* eslint-disable no-unused-vars */
import Vue from 'vue';
import '@babel/polyfill';
import vuetify from '@/plugins/vuetify';
import './plugins/thaiAddressInput';
import './plugins/gtm';
import './plugins/head';
import VueApexCharts from 'vue-apexcharts';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/firebase';
import './assets/css/style.sass';

Vue.config.productionTip = false;

Vue.component('apexchart', VueApexCharts);

navigator.serviceWorker.getRegistrations()
  .then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
