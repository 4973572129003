import firebase from '../index';

const storage = firebase.storage();
const storageRef = storage.ref();

const promoRef = storageRef.child('promotion');

const getPromoImgRef = imgName => promoRef.child(imgName).getDownloadURL();

export default {
  getPromoImgRef,
};
