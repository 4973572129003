<template>
<!-- eslint-disable max-len  -->
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 75 75">
        <defs>
        <linearGradient id="linear-gradient" x1="-0.83" y1="-0.54" x2="76.5" y2="76.21" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#d7362c"/>
            <stop offset="1" stop-color="#c31e2f"/>
        </linearGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
        <rect :class="classBackground" width="75" height="75" />
    </g>
    <path :class="classBlue" d="M59.08,34.55l-2.43-2a1,1,0,1,0-1.26,1.54l2.42,2a2.19,2.19,0,0,1,.8,1.7V43a1.88,1.88,0,0,1-1,1.65V38.56a1,1,0,0,0-1-1,1,1,0,0,0-1,1V57.25a1.5,1.5,0,0,1-1.5,1.5h-.78a1,1,0,0,0,0,2h.78a3.5,3.5,0,0,0,3.5-3.5V46.76a3.87,3.87,0,0,0,3-3.77v-5.2A4.19,4.19,0,0,0,59.08,34.55Z"/>
    <path :class="classBlue" d="M48,32.7a1,1,0,0,0-1.41-.14l-2.42,2a4.16,4.16,0,0,0-1.53,3.24V44.4L34.3,30.51a1,1,0,1,0-1.71,1l10.17,17a1,1,0,0,0,.86.49.82.82,0,0,0,.26,0,1,1,0,0,0,.74-1V37.79a2.19,2.19,0,0,1,.8-1.7l2.42-2A1,1,0,0,0,48,32.7Z"/>
    <path :class="classBlue" d="M52.61,47.35a1,1,0,0,0-2,0v9.9a1.5,1.5,0,0,1-3,0V38.57a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1V57.25a3.5,3.5,0,0,0,7,0Z"/>
    <path :class="classBlue" d="M51.61,33.49a6,6,0,1,0-6-6A6,6,0,0,0,51.61,33.49Zm0-10a4,4,0,1,1-4,4A4,4,0,0,1,51.61,23.44Z"/>
    <rect :class="classRed" x="19.97" y="22.05" width="2.27" height="8.55"/>
    <rect :class="classRed" x="26.14" y="26.78" width="2.27" height="3.82"/>
    <polygon :class="classRed" points="32.31 25.66 34.58 27.37 34.58 22.05 32.31 22.05 32.31 25.66"/>
    <path :class="classBlue" d="M39.09,22.49a.87.87,0,0,0-.26-1.2l-5.34-3.4a.86.86,0,0,0-1,0l-4.81,3.69-4.8-3.69a.87.87,0,0,0-1.22.16.86.86,0,0,0,.16,1.21l5.34,4.1a.9.9,0,0,0,1,0l4.85-3.73,4.83,3.08A.86.86,0,0,0,39.09,22.49Z"/>
    <path :class="classBlue" d="M30.23,51.2V35h2.08a1,1,0,0,0,0-2H16.67V15.9h24V33h-2.3a1,1,0,0,0,0,2h3.3a1,1,0,0,0,1-1V14.9a1,1,0,0,0-1-1h-26a1,1,0,0,0-1,1V34a1,1,0,0,0,1,1H28.23V51.2L20.4,59.05a1,1,0,0,0,.7,1.7,1,1,0,0,0,.71-.29L29.23,53l7.41,7.43a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41Z"/>

</svg>
</template>

<script>
export default {
  name: 'icon',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classBlue() {
      return this.isActive ? 'color-active-white' : 'color-blue';
    },
    classRed() {
      return this.isActive ? 'color-active-white' : 'color-red';
    },
    classBackground() {
      return this.isActive ? 'color-active' : 'hidden';
    },
  },
};
</script>

<style scoped>
.color-active {
   fill:url(#linear-gradient);
}
.color-active-white {
  fill:#fff;
}
.color-blue {
  fill: #003a70;
}
.color-red {
  fill: #f5333f;
}
.hidden {
  visibility: hidden;
}
</style>
