import Vue from 'vue';
import VueGtm from 'vue-gtm';
import router from '../router';

/**
 * @id type: String, require: true, format: 'GTM-xxxxxxx'
 * Your GTM ID
 *
 * @enabled type: Boolean, default:true, optional: true
 * Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie
 *
 * @debug type: Boolean, optional: true
 * Whether or not display console logs debugs
 *
 * @vueRouter type: Object(vue-router), optional: true
 * Pass the router instance to automatically sync with router
 *
 * @ignoredViews type: [ String ], optional: true, format: [ 'str1', 'str2' ]
 * If router, you can exclude some routes name (case insensitive)
 */

Vue.use(VueGtm, {
  id: 'GTM-KMH9WQ5',
  enabled: process.env.NODE_ENV === 'production',
  debug: false,
  vueRouter: router,
});

/**
 * For more infomation please visit
 * https://www.npmjs.com/package/vue-gtm
 */
