<template>
<!-- eslint-disable max-len  -->
 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 75 75">
        <defs>
        <linearGradient id="linear-gradient" x1="-0.83" y1="-0.54" x2="76.5" y2="76.21" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#d7362c"/>
            <stop offset="1" stop-color="#c31e2f"/>
        </linearGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
        <rect :class="classBackground" width="75" height="75" />
    </g>
    <path :class="classBlue" d="M50.93,18.64a4.35,4.35,0,0,0-1.41.24,4.26,4.26,0,0,1,0,8.06,4.35,4.35,0,0,0,1.41.24,4.27,4.27,0,1,0,0-8.54Z"/>
    <path :class="classRed" d="M62.6,29l-4.23-4.24a7.65,7.65,0,0,0,.22-1.8,7.74,7.74,0,1,0-15.47,0,7.65,7.65,0,0,0,.22,1.8L39.1,29a1,1,0,0,0,.71,1.7h3.3V34a1,1,0,0,0,.62.93,1,1,0,0,0,.38.07,1,1,0,0,0,.71-.29l4.23-4.23a7.65,7.65,0,0,0,1.8.22,7.73,7.73,0,0,0,1.81-.22l4.23,4.23a1,1,0,0,0,.71.29,1.06,1.06,0,0,0,.38-.07A1,1,0,0,0,58.6,34v-3.3h3.29A1,1,0,0,0,62.6,29ZM45.11,31.54V29.65a1,1,0,0,0-1-1H42.22l1.92-1.91A7.73,7.73,0,0,0,47,29.62Zm0-8.63a5.74,5.74,0,1,1,5.73,5.74A5.74,5.74,0,0,1,45.12,22.91ZM57.6,28.65a1,1,0,0,0-1,1v1.89l-1.92-1.92a7.76,7.76,0,0,0,2.89-2.88l1.91,1.91Z"/>
    <path :class="classBlue" d="M32.24,48.35H50.07a1,1,0,0,0,0-2H32.24a1,1,0,0,0,0,2Z"/>
    <path :class="classBlue" d="M55.83,48.07V36.39a1,1,0,0,0-2,0V48.07a1,1,0,1,0,2,0Z"/>
    <path :class="classBlue" d="M58.77,50.37H29.58a1,1,0,0,0-.93.62,1,1,0,0,0,.22,1.09,4.49,4.49,0,0,1,1.31,3.2,4.37,4.37,0,0,1-.13,1.08,1,1,0,0,0,1.95.48,6.85,6.85,0,0,0,.18-1.56,6.58,6.58,0,0,0-.67-2.91H58.33a4.55,4.55,0,0,1-3.5,7.46H30.37a5.76,5.76,0,0,1-5.75-5.76V21.83a1,1,0,0,0-2,0v4.62H18.47c.31-2.15,1.4-6.86,5.15-6.86H42a1,1,0,0,0,0-2H23.62c-6.75,0-7.24,9.41-7.25,9.81a1,1,0,0,0,.27.74,1,1,0,0,0,.72.31h5.26V54.07a7.77,7.77,0,0,0,7.75,7.76H54.83a6.55,6.55,0,0,0,4.65-11.16A1,1,0,0,0,58.77,50.37Z"/>
  </svg>
</template>

<script>
export default {
  name: 'icon',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classBlue() {
      return this.isActive ? 'color-active-white' : 'color-blue';
    },
    classRed() {
      return this.isActive ? 'color-active-white' : 'color-red';
    },
    classBackground() {
      return this.isActive ? 'color-active' : 'hidden';
    },
  },
};
</script>

<style scoped>
.color-active {
   fill:url(#linear-gradient);
}
.color-active-white {
  fill:#fff;
}
.color-blue {
  fill: #003a70;
}
.color-red {
  fill: #f5333f;
}
.hidden {
  visibility: hidden;
}
</style>
