<template>
<!-- eslint-disable max-len  -->
 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 75 75">
        <defs>
        <linearGradient id="linear-gradient" x1="-0.83" y1="-0.54" x2="76.5" y2="76.21" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#d7362c"/>
            <stop offset="1" stop-color="#c31e2f"/>
        </linearGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
        <rect :class="classBackground" width="75" height="75" />
    </g>
    <path :class="classBlue" d="M37.47,20.14a1,1,0,0,0,1-1V15.55a1,1,0,1,0-2,0v3.59A1,1,0,0,0,37.47,20.14Z"/>
    <path :class="classBlue" d="M31.62,20.35a1,1,0,0,0,.94.66A1.06,1.06,0,0,0,32.9,21a1,1,0,0,0,.59-1.28L32.17,16a1,1,0,1,0-1.88.68Z"/>
    <path :class="classBlue" d="M27.18,22.87a1,1,0,0,0,.77.36,1,1,0,0,0,.64-.24,1,1,0,0,0,.13-1.41L26.3,18.7A1,1,0,0,0,24.77,20Z"/>
    <path :class="classBlue" d="M21.09,24.18l3.42,2a1,1,0,0,0,1-1.74l-3.42-2a1,1,0,1,0-1,1.73Z"/>
    <path :class="classBlue" d="M49.62,26.55a1,1,0,0,0,.86.5,1,1,0,0,0,.5-.13l2.93-1.69a1,1,0,0,0-1-1.74L50,25.18A1,1,0,0,0,49.62,26.55Z"/>
    <path :class="classBlue" d="M47.29,23.54a1,1,0,0,0,.77-.35l2.34-2.8a1,1,0,0,0-1.53-1.28L46.52,21.9a1,1,0,0,0,.13,1.41A1,1,0,0,0,47.29,23.54Z"/>
    <path :class="classBlue" d="M42.05,21.23a1,1,0,0,0,.34.06,1,1,0,0,0,.94-.66L44.64,17a1,1,0,0,0-1.88-.68L41.45,20A1,1,0,0,0,42.05,21.23Z"/>
    <path :class="classBlue" d="M42.54,44.79a1,1,0,0,0-1.27,1.54l2.42,2A2.2,2.2,0,0,1,44.5,50V58.1a1,1,0,0,0,2,0V50A4.17,4.17,0,0,0,45,46.78Z"/>
    <path :class="classBlue" d="M32.46,44.79l-2.42,2A4.17,4.17,0,0,0,28.5,50V58.1a1,1,0,0,0,2,0V50a2.2,2.2,0,0,1,.81-1.7l2.42-2a1,1,0,0,0-1.27-1.54Z"/>
    <path :class="classBlue" d="M42.48,49.8a1,1,0,0,0-1,1v6.66a1,1,0,1,0,2,0V50.79A1,1,0,0,0,42.48,49.8Z"/>
    <path :class="classBlue" d="M32.52,49.8h0a1,1,0,0,0-1,1v6.67a1,1,0,1,0,2,0V50.8A1,1,0,0,0,32.52,49.8Z"/>
    <path :class="classBlue" d="M43.53,39.7a6,6,0,1,0-6,6A6,6,0,0,0,43.53,39.7Zm-6,4a4,4,0,1,1,4-4A4,4,0,0,1,37.5,43.72Z"/>
    <path :class="classBlue" d="M20.1,40.82l-2.42,2a4.17,4.17,0,0,0-1.54,3.25v8.07a1,1,0,1,0,2,0V46.06a2.2,2.2,0,0,1,.81-1.7l2.42-2a1,1,0,1,0-1.27-1.55Z"/>
    <path :class="classBlue" d="M20.16,45.83h0a1,1,0,0,0-1,1V53.5a1,1,0,0,0,2,0V46.84A1,1,0,0,0,20.16,45.83Z"/>
    <path :class="classBlue" d="M31.17,35.73a6,6,0,1,0-6,6A6,6,0,0,0,31.17,35.73Zm-6,4a4,4,0,1,1,4-4A4,4,0,0,1,25.14,39.76Z"/>
    <path :class="classBlue" d="M57.32,42.81l-2.42-2a1,1,0,0,0-1.27,1.55l2.42,2a2.2,2.2,0,0,1,.81,1.7v8.07a1,1,0,1,0,2,0V46.06A4.17,4.17,0,0,0,57.32,42.81Z"/>
    <path :class="classBlue" d="M54.84,45.83a1,1,0,0,0-1,1V53.5a1,1,0,0,0,2,0V46.82A1,1,0,0,0,54.84,45.83Z"/>
    <path :class="classBlue" d="M55.89,35.73a6,6,0,1,0-6,6A6,6,0,0,0,55.89,35.73Zm-6,4a4,4,0,1,1,4-4A4,4,0,0,1,49.86,39.76Z"/>
    <path :class="classRed" d="M37.47,22.29a13.15,13.15,0,0,0-11.25,5.83H29a11.26,11.26,0,0,1,8.47-3.58,11.25,11.25,0,0,1,8.47,3.58h2.78A13.15,13.15,0,0,0,37.47,22.29Z"/>
</svg>
</template>

<script>
export default {
  name: 'icon',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classBlue() {
      return this.isActive ? 'color-active-white' : 'color-blue';
    },
    classRed() {
      return this.isActive ? 'color-active-white' : 'color-red';
    },
    classBackground() {
      return this.isActive ? 'color-active' : 'hidden';
    },
  },
};
</script>

<style scoped>
.color-active {
   fill:url(#linear-gradient);
}
.color-active-white {
  fill:#fff;
}
.color-blue {
  fill: #003a70;
}
.color-red {
  fill: #f5333f;
}
.hidden {
  visibility: hidden;
}
</style>
