import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// const config = {
//   apiKey: 'AIzaSyBLR0MKvH0aCpmZL3P-2S5uP2QakXfm6g8',
//   authDomain: 'staging-achieve-plus.firebaseapp.com',
//   databaseURL: 'https://staging-achieve-plus.firebaseio.com',
//   projectId: 'staging-achieve-plus',
//   storageBucket: 'staging-achieve-plus.appspot.com',
//   messagingSenderId: '756861430870',
// };

const config = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTH,
  databaseURL: process.env.VUE_APP_DATABASE,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGE,
};

firebase.initializeApp(config);
firebase.firestore();
firebase.storage();
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export default firebase;
