<template>
  <v-app>
    <Loading
     :show="show"
     :label="label">
    </Loading>
    <Navbar />
    <v-content>
      <router-view />
      <v-btn
        class="white--text"
        target="_blank"
        color="green"
        bottom
        right
        fab
        fixed
        href="https://line.me/ti/p/%40achieveplus"
      >
        <v-icon class="white-text" large>fab fa-line</v-icon>
      </v-btn>
      <Announcement />
      <Footer />
    </v-content>
    <Cookies />
  </v-app>
</template>

<script>
import Loading from 'vue-full-loading';
import Navbar from './components/Navbar.vue';
import Cookies from './components/CookiesAlert.vue';
import Footer from './components/Footer.vue';
import Announcement from './components/Announcement.vue';


export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    Loading,
    Cookies,
    Announcement,
  },
  data() {
    return {
      show: null,
      label: 'Loading...',
    };
  },
  async mounted() {
    await this.$store.dispatch('init')
      .then(async () => {
        await this.$store.dispatch('setLoading', true);
        const { user } = this.$store.getters;
        const { uid } = user;
        await this.$store.dispatch('getProfile', { uid });
        await this.$store.dispatch('setLoading', false);
      })
      .catch(() => {
        this.$store.dispatch('beAnon');
        this.$store.dispatch('setLoading', false);
      });
  },
  methods: {},
  computed: {
    loader() {
      return this.$store.getters.loading;
    },
  },
  watch: {
    loader(bool) {
      if (bool) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  head() {
    const descriptionSEO = 'หลักสูตร Digital Skills (Data, IT, Media, Communication, Technology) ที่กระชับที่สุด เหมาะกับคนไทยที่สุด เข้าใจง่ายที่สุด นำไปใช้งานได้จริง ในรูปแบบ "Blended Learning" (Online ผสม Offline) โดยทีมงาน Achieve พร้อมผลักดันผู้เรียนของเราให้ก้าวไปอีกขั้นอยู่เสมอ';
    return {
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: descriptionSEO },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: 'Digital Skills, Blended Learning' },
        { property: 'og:url', content: 'https://www.achieve.plus' },
        { property: 'og:title', content: 'Achieve Plus' },
        { property: 'og:type', content: 'website' },
        { property: 'og:description', content: descriptionSEO },
        { name: 'twitter:title', content: 'Achieve Plus' },
        { name: 'twitter:site', content: 'https://www.achieve.plus' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:image', content: 'https://www.achieve.plus/img/icons/favicon192.png' },
        { name: 'twitter:description', content: descriptionSEO },
        { property: 'og:image', content: 'https://www.achieve.plus/img/icons/favicon192.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { itemprop: 'name', content: 'Achieve Plus' },
        { itemprop: 'description', content: descriptionSEO },
      ],
      // link: [
      //   { rel: 'stylesheet', href: './views/Login.vue' },
      //   { rel: 'stylesheet', href: './views/member/Index.vue' },
      //   { rel: 'stylesheet', href: './views/workshop/Index.vue' },
      //   { rel: 'stylesheet', href: './views/About.vue' },
      //   { rel: 'stylesheet', href: './views/Signup.vue' },
      // ],
    };
  },
};
</script>

<style lang="sass">
  @import './assets/css/style.sass'
</style>
