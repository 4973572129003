const defaultState = {
  loading: false,
  error: null,
  isPop: false,
  to: '/',
};

const actions = {
  clearError({ commit }) {
    commit('clearError');
  },
  setError({ commit }, payload) {
    commit('setError', payload);
  },
  setLoading({ commit }, payload) {
    commit('setLoading', payload);
  },
  setPopLogin({ commit }, payload) {
    commit('setPopLogin', payload);
  },
  setToPath({ commit }, payload) {
    commit('setTo', payload);
  },
};

const mutations = {
  setLoading(state, payload) {
    state.loading = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  clearError(state) {
    state.error = null;
  },
  setPopLogin(state, payload) {
    state.isPop = payload;
  },
  setTo(state, payload) {
    state.to = payload;
  },
};

const getters = {
  loading: state => state.loading,
  error: state => state.error,
  isPop: state => state.isPop,
  toPath: state => state.to,
};

export default {
  state: defaultState,
  actions,
  mutations,
  getters,
};
