import firebase from './index';

const google = new firebase.auth.GoogleAuthProvider();
const facebook = new firebase.auth.FacebookAuthProvider();
const microsoft = new firebase.auth.OAuthProvider('microsoft.com');
const emailAuth = (email, password) => firebase.auth.EmailAuthProvider.credential(email, password);

const auth = firebase.auth();

const anonymousLogin = () => firebase.auth().signInAnonymously();

const firebaseUser = () => firebase.auth().currentUser;

const createUser = ({ email, password }) => firebase.auth()
  .createUserWithEmailAndPassword(email, password);

const loginUser = ({ email, password }) => firebase.auth()
  .signInWithEmailAndPassword(email, password);

const sendVerify = () => firebase.auth().currentUser.sendEmailVerification();

const loginWithCredential = credential => firebase.auth()
  .signInAndRetrieveDataWithCredential(credential);

const loginGoogle = () => firebase.auth().signInWithRedirect(google);
const loginFacebook = () => firebase.auth().signInWithRedirect(facebook);
const loginMicrosoft = () => firebase.auth().signInWithRedirect(microsoft);

const linkGoogle = () => firebase.auth().currentUser.linkWithRedirect(google);
const linkFacebook = () => firebase.auth().currentUser.linkWithRedirect(facebook);
const linkMicrosoft = () => firebase.auth().currentUser.linkWithRedirect(microsoft);
const linkEmail = async (email, password) => auth.currentUser
  .linkAndRetrieveDataWithCredential(emailAuth(email, password));

const getLoginResult = () => firebase.auth().getRedirectResult();

const upgradeUser = credential => firebase.auth().currentUser
  .linkAndRetrieveDataWithCredential(credential);

const deleteAnonUser = () => firebase.auth().currentUser.delete();

const logout = () => firebase.auth().signOut();

const sendResetPassword = ({ email }) => firebase.auth()
  .sendPasswordResetEmail(email);

const changePassword = ({ newPassword }) => firebase.auth()
  .currentUser.updatePassword(newPassword);

export default {
  auth,
  anonymousLogin,
  firebaseUser,
  createUser,
  loginUser,
  sendVerify,
  loginWithCredential,
  loginGoogle,
  loginFacebook,
  loginMicrosoft,
  linkGoogle,
  linkFacebook,
  linkMicrosoft,
  linkEmail,
  upgradeUser,
  deleteAnonUser,
  logout,
  sendResetPassword,
  changePassword,
  getLoginResult,
};
