import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import Home from './views/Home.vue';

const { errorMsg } = require('./static/errorMessage');

const requireAuth = async (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if ((from.name === 'login' && to.name === 'memberRegister') || to.name === 'memberRegister') {
      const { uid } = store.getters.user;
      await store.dispatch('getProfile', { uid })
        .then(() => {
          const { isMember, PDPA } = store.getters.profile;
          if (isMember && PDPA) {
            next('/profile/member');
          }
        });
    }
    next();
  } else {
    store.dispatch('setError', errorMsg['req/login']);
    store.dispatch('setToPath', to.fullPath);
    localStorage.toPath = to.fullPath;
    next('/login');
  }
};

const authNoEnter = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next(from);
  }
  next();
};

const userIsMember = async (to, from, next) => {
  if (store.getters.isAuthenticated) {
    const { uid } = store.getters.user;
    await store.dispatch('getProfile', { uid });
    const { isMember, PDPA } = store.getters.profile;
    if (isMember && PDPA && to.name === 'member') {
      next('/profile/member');
    } else if (!(isMember && PDPA) && to.name === 'profilemember') {
      next('/member');
    }
  }
  next();
};

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/test',
      name: 'test',
      component: () => import('./views/Test.vue'),
    },
    {
      path: '/404',
      name: 'notFound',
      component: () => import(/* webpackChunkName: "about" */ './views/404.vue'),
    },
    {
      path: '/503',
      name: '503',
      component: () => import(/* webpackChunkName: "about" */ './views/503.vue'),
    },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/index.html',
      name: 'indexHome',
      component: Home,
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    },
    {
      path: '/login',
      name: 'login',
      beforeEnter: authNoEnter,
      component: () => import(/* webpackChunkName: "about" */ './views/Login.vue'),
    },
    // {
    //   path: '/store',
    //   name: 'store',
    //   component: () => import('./views/products/Index.vue'),
    // },
    {
      path: '/store',
      name: 'workshop',
      component: () => import('./views/workshop/Index.vue'),
    },
    // {
    //   path: '/workshops/:id',
    //   name: 'workshopDetail',
    //   props: true,
    //   component: () => import('./views/workshop/WorkshopDetail.vue'),
    // },
    {
      path: '/workshops/1',
      name: 'workshopDetail1',
      component: () => import(/* webpackChunkName: "about" */ './views/workshop/workshopDetail1.vue'),
    },
    {
      path: '/workshops/2',
      name: 'workshopDetail2',
      component: () => import(/* webpackChunkName: "about" */ './views/workshop/workshopDetail2.vue'),
    },
    {
      path: '/workshops/3',
      name: 'workshopDetail3',
      component: () => import(/* webpackChunkName: "about" */ './views/workshop/workshopDetail3.vue'),
    },
    {
      path: '/workshops/4',
      name: 'workshopDetail4',
      component: () => import(/* webpackChunkName: "about" */ './views/workshop/workshopDetail4.vue'),
    },
    {
      path: '/workshops/5',
      name: 'workshopDetail5',
      component: () => import(/* webpackChunkName: "about" */ './views/workshop/workshopDetail5.vue'),
    },
    {
      path: '/workshops/6',
      name: 'workshopDetail6',
      component: () => import(/* webpackChunkName: "about" */ './views/workshop/workshopDetail6.vue'),
    },
    {
      path: '/workshops/7',
      name: 'workshopDetail7',
      component: () => import('./views/workshop/workshopDetail7.vue'),
    },
    {
      path: '/workshops/8',
      name: 'workshopDetail8',
      component: () => import('./views/workshop/workshopDetail8.vue'),
    },
    {
      path: '/workshops/9',
      name: 'workshopDetail9',
      component: () => import('./views/workshop/workshopDetail9.vue'),
    },
    {
      path: '/workshops/10',
      name: 'workshopDetail10',
      component: () => import('./views/workshop/workshopDetail10.vue'),
    },
    {
      path: '/workshops/11',
      name: 'workshopDetail11',
      component: () => import('./views/workshop/workshopDetail11.vue'),
    },
    {
      path: '/workshops/12',
      name: 'workshopDetail12',
      component: () => import('./views/workshop/workshopDetail12.vue'),
    },
    {
      path: '/workshops/13',
      name: 'workshopDetail13',
      component: () => import('./views/workshop/workshopDetail13.vue'),
    },
    {
      path: '/workshops/14',
      name: 'workshopDetail14',
      component: () => import('./views/workshop/workshopDetail14.vue'),
    },
    {
      path: '/workshops/15',
      name: 'workshopDetail15',
      component: () => import('./views/workshop/workshopDetail15.vue'),
    },
    {
      path: '/workshops/16',
      name: 'workshopDetail16',
      component: () => import('./views/workshop/workshopDetail16.vue'),
    },
    {
      path: '/workshops/17',
      name: 'workshopDetail17',
      component: () => import('./views/workshop/workshopDetail17.vue'),
    },
    {
      path: '/workshops/18',
      name: 'workshopDetail18',
      component: () => import('./views/workshop/workshopDetail18.vue'),
    },
    {
      path: '/cv/1',
      name: 'companyVisit1',
      component: () => import('./views/workshop/CompanyVisit1.vue'),
    },
    {
      path: '/pathways/1',
      name: 'pathwayDetail1',
      component: () => import('./views/pathways/pathwayDetail1.vue'),
    },
    {
      path: '/pathways/2',
      name: 'pathwayDetail2',
      component: () => import('./views/pathways/pathwayDetail2.vue'),
    },
    {
      path: '/pathways/3',
      name: 'pathwayDetail3',
      component: () => import('./views/pathways/pathwayDetail3.vue'),
    },
    {
      path: '/courses/1',
      name: 'courseDetail1',
      component: () => import('./views/courses/courseDetail1.vue'),
    },
    {
      path: '/product/1',
      name: 'productDetail1',
      component: () => import('./views/products/ProductDetail1.vue'),
    },
    {
      path: '/product/2',
      name: 'productDetail2',
      component: () => import('./views/products/ProductDetail2.vue'),
    },
    {
      path: '/product/3',
      name: 'productDetail3',
      component: () => import('./views/products/ProductDetail3.vue'),
    },
    {
      path: '/product/4',
      name: 'productDetail4',
      component: () => import('./views/products/ProductDetail4.vue'),
    },
    {
      path: '/member',
      name: 'member',
      beforeEnter: userIsMember,
      component: () => import(/* webpackChunkName: "about" */ './views/member/Index.vue'),
    },
    {
      path: '/member/register',
      name: 'memberRegister',
      beforeEnter: requireAuth,
      component: () => import(/* webpackChunkName: "about" */ './views/member/MemberStep.vue'),
    },
    {
      path: '/member/success',
      name: 'memberSuccess',
      beforeEnter: requireAuth,
      component: () => import(/* webpackChunkName: "about" */ './views/member/Success.vue'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import(/* webpackChunkName: "about" */ './views/Logout.vue'),
    },
    {
      path: '/link',
      name: 'link',
      component: () => import(/* webpackChunkName: "about" */ './views/Link.vue'),
    },
    {
      path: '/profile',
      component: () => import(/* webpackChunkName: "about" */ './views/profile/Index.vue'),
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          component: () => import('./views/profile/Profile.vue'),
        },
        {
          path: 'index',
          name: 'profile',
          component: () => import('./views/profile/Profile.vue'),
          props: true,
        },
        {
          path: 'courses',
          name: 'courses',
          component: () => import('./views/profile/CoursesList.vue'),
        },
        {
          path: 'workshops',
          name: 'workshops',
          component: () => import('./views/profile/WorkshopsList.vue'),
        },
        {
          path: 'payment',
          name: 'payment',
          component: () => import('./views/profile/Payment.vue'),
        },
        {
          path: 'member',
          name: 'profilemember',
          beforeEnter: userIsMember,
          component: () => import('./views/profile/Member.vue'),
        },
      ],
    },
    {
      path: '/payment',
      component: () => import(/* webpackChunkName: "about" */ './views/payment/Index.vue'),
      beforeEnter: requireAuth,
      children: [
        {
          path: 'review/:id',
          name: 'view',
          component: () => import('./views/payment/View.vue'),
        },
        {
          path: 'cart/:sku',
          name: 'review',
          component: () => import('./views/payment/Review.vue'),
        },
        {
          path: 'method',
          name: 'method',
          component: () => import('./views/payment/Method.vue'),
        },
        {
          path: 'success',
          name: 'success',
          component: () => import('./views/payment/Success.vue'),
        },
        {
          path: 'fail',
          name: 'fail',
          component: () => import('./views/payment/Fail.vue'),
        },
        {
          path: 'complete',
          name: 'complete',
          component: () => import('./views/payment/Complete.vue'),
        },
      ],
    },
    {
      path: '/termofservice',
      name: 'termofservice',
      component: () => import('./views/TermOfService.vue'),
    },
    {
      path: '/privacypolicy',
      name: 'privacypolicy',
      component: () => import('./views/PrivacyPolicy.vue'),
    },
    {
      path: '/cookiespolicy',
      name: 'cookiespolicy',
      component: () => import('./views/CookiesPolicy.vue'),
    },
    {
      path: '/studio',
      name: 'studio',
      beforeEnter: requireAuth,
      component: () => import('./views/studio/Index.vue'),
    },
    {
      path: '*',
      name: 'catchAll',
      component: Home,
    },
    {
      path: '/survey',
      component: () => import('./views/survey/Index.vue'),
      children: [
        {
          path: '',
          component: () => import('./views/survey/Survey.vue'),
        },
        {
          path: 'index',
          name: 'survey',
          component: () => import('./views/survey/Survey.vue'),
          props: true,
        },
        // {
        //   path: 'question',
        //   name: 'question',
        //   component: () => import('./views/survey/Question.vue'),
        // },
        {
          path: 'question',
          name: 'question',
          component: () => import('./views/survey/Question2.vue'),
        },
        // {
        //   path: 'result',
        //   name: 'result',
        //   component: () => import('./views/survey/Result.vue'),
        //   props: true,
        // },
        {
          path: 'result',
          name: 'result',
          component: () => import('./views/survey/Result2.vue'),
          props: true,
        },
      ],
    },
  ],
});
