import services from '../../firebase/services';
import userService from '../../firebase/firestore/users';
import store from '../index';
import cumaService from '../../cuma/service';
import firebase from '../../firebase/index';

const { errorMsg } = require('../../static/errorMessage');

const defaultState = {
  user: undefined,
  isAuthenticated: false,
  isAnonymous: false,
  providerData: undefined,
  cuma: undefined,
  cumaLinked: undefined,
  loginMethod: undefined,
  isLink: false,
  linkMethod: undefined,
};

const methods = {
  email: { method: 'email', providerId: 'password' },
  google: { method: 'googleEmail', providerId: 'google.com' },
  facebook: { method: 'facebookEmail', providerId: 'facebook.com' },
  microsoft: { method: 'microsoftEmail', providerId: 'microsoft.com' },
};

const loginProcessing = (commit, user, loginMethod, isLink, linkMethod) => {
  const currMethod = isLink ? linkMethod : loginMethod;
  const { uid, providerData } = user;
  const { email, phoneNumber, displayName } = providerData
    .filter(obj => obj.providerId === methods[currMethod].providerId)[0];
  const data = {
    lastLogged: firebase.firestore.Timestamp.now(),
    [methods[currMethod].method]: email || phoneNumber || displayName,
  };
  userService.setUser({ uid, data });
  // commit('setUser', user);
  // commit('setProviderData', providerData);
  // commit('setIsAuthenticated', true);
  commit('setIsAnonymous', false);
  commit('setIsLink', false);
  store.dispatch('getProfile', { uid });
};

const deleteAnonUser = () => {
  services.deleteAnonUser()
    .catch((error) => {
      console.error(error);
    });
};

const logError = (commit, error) => {
  const err = error.message;
  commit('setError', errorMsg[error.code]);
  console.log(err);
};

const actions = {
  currUser: ({ commit }) => {
    if (!services.firebaseUser()) {
      commit('setIsAuthenticated', false);
    } else {
      commit('setIsAuthenticated', true);
    }
  },
  init: ({ commit }) => new Promise((resolve, reject) => {
    services.getLoginResult()
      .then((result) => {
        const { user } = result;
        if (user) {
          const { isLink, loginMethod, linkMethod } = store.getters;
          loginProcessing(commit, user, loginMethod, isLink, linkMethod);
        }
      })
      .catch((err) => {
        logError(commit, err);
      });
    services.auth.onAuthStateChanged((result) => {
      if (result) console.log(result.uid);
      if (result && !result.isAnonymous) {
        const user = result || null;
        commit('setProviderData', user.providerData);
        commit('setIsAuthenticated', !!user);
        commit('setIsLink', false);
        resolve(commit('setUser', user));
      } else {
        reject();
      }
    });
  }),
  beAnon: ({ commit }) => {
    services.anonymousLogin()
      .then(() => {
        commit('setIsAnonymous', true);
      });
  },
  signup: ({ commit }, { email, password }) => {
    services.createUser({ email, password })
      .then((user) => {
        loginProcessing(commit, user.user, 'email', false, null);
        services.sendVerify();
      })
      .catch((error) => {
        logError(commit, error);
      });
  },
  login: ({ commit }, { email, password }) => {
    services.loginUser({ email, password })
      .then((user) => {
        loginProcessing(commit, user.user, 'email', false, null);
        commit('setLoginMethod', 'email');
      })
      .catch((error) => {
        logError(commit, error);
      });
  },
  googleLogin: ({ commit, state }) => {
    if (state.isAnonymous) {
      deleteAnonUser();
    }
    commit('setLoginMethod', 'google');
    services.loginGoogle();
  },
  facebookLogin: ({ commit, state }) => {
    if (state.isAnonymous) {
      deleteAnonUser();
    }
    commit('setLoginMethod', 'facebook');
    services.loginFacebook();
  },
  microsoftLogin: ({ commit, state }) => {
    if (state.isAnonymous) {
      deleteAnonUser();
    }
    commit('setLoginMethod', 'microsoft');
    services.loginMicrosoft();
  },
  googleLink: ({ commit }) => {
    commit('setIsLink', true);
    commit('setLinkMethod', 'google');
    services.linkGoogle();
  },
  facebookLink: ({ commit }) => {
    commit('setIsLink', true);
    commit('setLinkMethod', 'facebook');
    services.linkFacebook();
  },
  microsoftLink: ({ commit }) => {
    commit('setIsLink', true);
    commit('setLinkMethod', 'microsoft');
    services.linkMicrosoft();
  },
  emailLink: async ({ commit }, { email, password }) => {
    commit('setIsLink', true);
    commit('setLinkMethod', 'email');
    const res = await services.linkEmail(email, password);
    const { user } = res;
    const { uid, providerData } = user;
    const provider = providerData.filter(info => info.providerId === 'password')[0];
    const data = {
      email: provider.email,
    };
    userService.setUser({ uid, data });
    commit('setIsAnonymous', false);
    store.dispatch('getProfile', { uid });
  },
  cumaLink: ({ commit, state }, { username, password }) => new Promise((resolve, reject) => {
    cumaService.login({ username, password })
      .then((result) => {
        const { uid } = state.user;
        const data = {
          cumaEmail: username,
          cumaToken: result.data.access_token,
        };
        userService.setUser({ uid, data });
        commit('setCumaLinked', true);
        resolve(result.data);
      })
      .catch(() => {
        reject(commit('setError', errorMsg['cuma/error']));
      });
  }),
  cumaCheckEnroll: ({ commit }, { token }) => new Promise((resolve, reject) => {
    cumaService.haveEnroll({ token })
      .then((result) => {
        resolve(commit('setCuma', result.data.haveEnroll));
      })
      .catch(() => {
        reject(commit('setCuma', false));
      });
  }),
  logout: ({ commit }) => {
    services.logout()
      .then(() => {
        commit('setUser', null);
        commit('setProviderData', null);
        commit('setIsAuthenticated', false);
      })
      .catch((error) => {
        logError(commit, error);
      });
  },
  sendResetPassword: ({ commit }, { email }) => {
    services.sendResetPassword({ email })
      .catch((error) => {
        logError(commit, error);
      });
  },
};

const mutations = {
  setUser: (state, user) => {
    state.user = user;
  },
  setProviderData: (state, payload) => {
    state.providerData = payload;
  },
  setIsAuthenticated: (state, payload) => {
    state.isAuthenticated = payload;
  },
  setIsAnonymous: (state, payload) => {
    state.isAnonymous = payload;
  },
  setCuma: (state, payload) => {
    state.cuma = payload;
  },
  setCumaLinked: (state, payload) => {
    state.cumaLinked = payload;
  },
  setLoginMethod: (state, payload) => {
    state.loginMethod = payload;
  },
  setIsLink: (state, payload) => {
    state.isLink = payload;
  },
  setLinkMethod: (state, payload) => {
    state.linkMethod = payload;
  },
};

const getters = {
  user: state => state.user,
  isAuthenticated: state => state.isAuthenticated,
  isAnonymous: state => state.isAnonymous,
  cuma: state => state.cuma,
  cumaLinked: state => state.cumaLinked,
  loginMethod: state => state.loginMethod,
  isLink: state => state.isLink,
  linkMethod: state => state.linkMethod,
};

export default {
  state: defaultState,
  actions,
  mutations,
  getters,
};
