<template>
  <v-footer
      class="text-xs-center"
      height="auto"
    >
    <v-container>
      <v-row align="center" justify="center">
        <a href="https://www.chulamoocachieve.com/" rel="nofollow" target="_blank"> CHULA MOOC Achieve </a> <br/>
      </v-row>
      <v-row align="center" justify="center">
        <router-link to="/about" class="pr-2">
          <a>ABOUT US</a>
        </router-link>|
        <router-link to="/termofservice" class="px-2">
          <a> TERMS OF SERVICE </a>
        </router-link>|
        <router-link to="/privacypolicy" class="px-2">
          <a> PRIVACY POLICY </a>
        </router-link>|
        <router-link to="/cookiespolicy" class="pl-2">
          <a> COOKIES POLICY</a>
        </router-link>
      </v-row>
      <br />
      <v-divider></v-divider>
      <br />
      <v-row align="center" justify="center">
        <v-img
          :src="require('@/assets/image/achieveplus-logo.svg')"
          contain
          height="50"
        />
      </v-row>
      <v-row align="center" justify="center">
        &copy;2019 — <strong>Achieve Plus</strong>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
// import auth from '@/store';

export default {
  created() {
    const dbdScript = document.createElement('script');
    dbdScript.setAttribute('src', 'https://www.trustmarkthai.com/callbackData/initialize.js?t=1f-25-5-ecf37fbf41e80538f38ad4268768f2d18602b1fa143');
    dbdScript.id = 'dbd-init';
    document.body.appendChild(dbdScript);
    const dbdDiv = document.createElement('div');
    dbdDiv.id = 'Certificate-banners';
    dbdDiv.style.backgroundColor = '#f5f6f6';
    dbdDiv.style.justifyContent = 'center';
    dbdDiv.style.display = 'flex';
    document.body.appendChild(dbdDiv);
  },
};
</script>

<style scoped>
.theme--light.v-card {
  background-color: none;
}
</style>
