<template>
  <v-dialog
    v-model="show"
    max-width="450"
  >
    <v-card class="">
      <v-container>
        <v-row justify="end">
          <v-icon class="pr-4" @click="show = false">close</v-icon>
          <v-img
            :src="image"
            @click="goto()"
          ></v-img>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import setting from '../firebase/firestore/setting';
import firebaseStorage from '../firebase/storage/index';

const { errorMsg } = require('../static/errorMessage');

export default {
  name: 'AnouncementPopup',
  data() {
    return {
      show: false,
      ready: false,
      image: '',
      link: '/',
      code: '',
    };
  },
  created() {
    window.addEventListener('beforeunload', this.reset());
  },
  async mounted() {
    try {
      const annConfig = await setting.annConfig();
      const {
        active, startDate, endDate, image, link, code,
      } = annConfig;
      if (active) {
        if (startDate < setting.fbTime() && endDate > setting.fbTime()) {
          const showed = this.$store.getters.showedAn;
          if (!showed) {
            this.show = true;
            this.ready = true;
            this.image = await firebaseStorage.getPromoImgRef(image);
            this.link = link;
            this.code = code;
          }
        }
      }
    } catch (err) {
      this.$store.dispatch('setError', errorMsg['connection/unavailable']);
    }
  },
  watch: {
    show(val) {
      if (!val) {
        this.close();
        this.copyCode();
      }
    },
  },
  methods: {
    close() {
      if (!this.show && this.ready) {
        this.$store.dispatch('setShowedAn', true);
      }
    },
    reset() {
      if (window.performance.navigation.type !== 1) {
        this.$store.dispatch('setShowedAn', false);
      }
    },
    goto() {
      this.show = false;
      this.$router.push(this.link);
      this.close();
    },
    copyCode() {
      if (this.code) {
        navigator.clipboard.writeText(this.code);
        this.$store.dispatch('setError', `Copy ${this.code} code to clipboard`);
      }
    },
  },
};
</script>

<style>

</style>
