<template>
  <v-alert
    class="floating"
    dismissible
    v-model="show"
    type="info"
    :close="{ toggle: close() }"
  >
    เราใช้คุกกี้เพื่อปรับปรุงไซต์ของเราและประสบการณ์ของคุณ
    หากคุณดำเนินการต่อ ทางเราจะถือว่าคุณยอมรับนโยบายคุกกี้ของเรา.
    <router-link to="/cookiespolicy">ค้นหาเพิ่มเติม</router-link>
  </v-alert>
</template>

<script>
export default {
  name: 'CookiesAlert',
  data() {
    return {
      show: false,
      ready: false,
    };
  },
  mounted() {
    const showed = this.$store.getters.showedCk;
    if (!showed) {
      this.show = true;
      this.ready = true;
    }
  },
  methods: {
    close() {
      if (!this.show && this.ready) {
        this.$store.dispatch('setShowedCk', true);
      }
    },
  },
};
</script>

<style scoped>
  .floating {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
  }
</style>
