<template>
  <v-dialog
    v-model="forgotPanel"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <a v-on="on"><span class="grey-text underline">{{ text }}</span></a>
    </template>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>
          <span class="navy-text text-xs-center">RESET PASSWORD</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            name="forgotemail"
            label="EMAIL"
            type="email"
            v-model="forgotemail"
            :rules="emailRules"
            required>
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="#f5333f"
          outlined
          @click="forgotPanel = false"
        >
          CANCEL
        </v-btn>
        <v-btn
          color="#f5333f"
          :disabled="!valid"
          @click="sendResetPassword"
        >
          <span class="white-text">RESET</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ResetPassword',
  props: {
    text: String,
  },
  data() {
    return {
      valid: false,
      forgotPanel: false,
      forgotemail: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    };
  },
  methods: {
    sendResetPassword() {
      this.forgotPanel = false;
      if (this.$refs.form.validate()) {
        this.$store.dispatch('sendResetPassword', {
          email: this.forgotemail,
        });
        this.forgotemail = '';
      }
    },
  },
};
</script>
