<template>
<!-- eslint-disable max-len  -->
 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 75 75">
        <defs>
        <linearGradient id="linear-gradient" x1="-0.83" y1="-0.54" x2="76.5" y2="76.21" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#d7362c"/>
            <stop offset="1" stop-color="#c31e2f"/>
        </linearGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
        <rect :class="classBackground" width="75" height="75" />
    </g>
    <path :class="classBlue" d="M69.34,22.44,66.87,20a2,2,0,0,0-2.82,0l-1.76,1.76-.21-.21a3.28,3.28,0,0,0-4.63,0l-6.86,6.86a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l6.86-6.85a1.27,1.27,0,0,1,1.8,0l.21.2L42.13,41.89l-1.4,1.4a.91.91,0,0,0-.22.33L39,47.2a1,1,0,0,0,.22,1.09l.17.17-1.14,1.15a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l1.15-1.14.17.17a1,1,0,0,0,.71.29,1,1,0,0,0,.38-.07l3.58-1.47a.91.91,0,0,0,.33-.22l1.4-1.4L69.34,25.26A2,2,0,0,0,69.34,22.44ZM58.9,27.94l2.47,2.47L46.71,45.06,44.25,42.6ZM42,48.17l-.41-.42h0l-.41-.41,1.14-2.78.55-.55,2.47,2.47-.55.55ZM62.78,29l-2.47-2.46L63,23.86h0l2.46-2.46,2.47,2.47Z"/>
    <path :class="classBlue" d="M53,42.76a1,1,0,0,0-1,1V62H19.58V16.59h5.74V18a2.5,2.5,0,0,0,5,0V16.59h3V18a2.5,2.5,0,1,0,5,0V16.59h3V18a2.51,2.51,0,0,0,5,0V16.59H52v7.13a1,1,0,0,0,2,0V15.59a1,1,0,0,0-1-1H46.29V13.2a2.51,2.51,0,0,0-5,0v1.39h-3V13.2a2.5,2.5,0,0,0-5,0v1.39h-3V13.2a2.5,2.5,0,1,0-5,0v1.39H18.58a1,1,0,0,0-1,1V63a1,1,0,0,0,1,1H53a1,1,0,0,0,1-1V43.76A1,1,0,0,0,53,42.76ZM43.28,13.2a.51.51,0,0,1,1,0V18a.51.51,0,0,1-1,0Zm-8,0a.51.51,0,0,1,.5-.5.5.5,0,0,1,.5.5V18a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5Zm-8,0a.5.5,0,1,1,1,0V18a.5.5,0,0,1-1,0Z"/>
    <polygon :class="classRed" points="39.05 60.06 50.14 60.06 50.14 49.51 39.05 60.06" />
</svg>
</template>

<script>
export default {
  name: 'icon',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classBlue() {
      return this.isActive ? 'color-active-white' : 'color-blue';
    },
    classRed() {
      return this.isActive ? 'color-active-white' : 'color-red';
    },
    classBackground() {
      return this.isActive ? 'color-active' : 'hidden';
    },
  },
};
</script>

<style scoped>
.color-active {
   fill:url(#linear-gradient);
}
.color-active-white {
  fill:#fff;
}
.color-blue {
  fill: #003a70;
}
.color-red {
  fill: #f5333f;
}
.hidden {
  visibility: hidden;
}
</style>
