<template>
  <div>
    <v-lazy
      :options="lazyOption"
      transition="fade-transition"
    >
      <v-card>
        <v-carousel v-model="selectedBanner"
          continuous
          :show-arrows="false"
          class="container-banner"
          cycle
        >
          <v-carousel-item
            key="0"
            class="bn1-bg container-banner"
          >
            <v-card class="window-banner" color="transparent" outlined>
              <v-container class="ma-0">
                <v-col cols="12" md="6">
                  <v-row class="font-weight-black res-banner">
                    <span class="navy-text">Achieve</span>
                    <span class="red-text pl-4">Plus</span>
                  </v-row>
                  <v-row class="subhead-banner navy-text">
                    กล้าเรียนรู้ ก้าวสู่ความสำเร็จไปด้วยกัน
                  </v-row>
                  <v-row>
                    <v-col class="ma-0 pa-0" cols="12" sm="6" md="10" xl="8">
                      <span class="base-text text-justify d-none d-md-flex content-banner">
                        คอร์สเรียนของ Achieve Plus มีความทันสมัย เรียนที่ไหนก็ได้ ทุกที่
                        ทุกเวลา เนื้อหาเป็นภาษาไทย เข้าใจง่าย เข้าถึงได้ทุกคน พร้อมกรณี
                        ศึกษาของคนไทย สามารถนำไปปรับใช้ได้ในชีวิตจริง
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="pl-sm-1 pa-md-8 py-md-3 pt-xl-0">
                    <v-btn class=" bt-lan" to="/store" color="error" rounded>
                      <span class="white-text">เริ่มเรียนเลย</span>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-container>
            </v-card>
          </v-carousel-item>
          <v-carousel-item
            key="1"
            class="bn2-bg container-banner"
          >
            <v-card
              class="window-banner"
              color="transparent"
              @click="goStudio"
              outlined>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-lazy>
    <v-lazy
      :options="lazyOption"
      transition="fade-transition"
      min-height="200"
    >
      <div class="container-bg">
        <v-container>
          <v-row
            class="my-5 font-weight-black headline navy-text"
            justify="center"
          >
            รูปแบบคอร์สเรียน
            </v-row>
            <v-row justify="center">
            <v-col cols="12" sm="10" xl="7">
              <div class="card-con">
                <v-card class="pa-1">
                  <v-tabs
                    centered
                    grow
                    :value="selectedProBriefs"
                    max-height="75"
                  >
                    <v-tab
                      class="pa-1 subtitle-2 font-weight-bold"
                      v-for="(item, index) in proBriefs"
                      :key="index"
                      active-class="select-tab"
                      @click="selectedProBriefs = index"
                    >
                      <span :class="`${selectedProBriefs === index
                        ? 'white-text' : 'navy-text'}`"
                      >
                      {{item.header}}</span>
                    </v-tab>
                    <v-tab-item
                      disabled
                      v-for="(pro, index) in proBriefs"
                      :key="index">
                      <v-container class="pa-md-4 px-lg-12">
                        <v-card-title class="title navy-text font-weight-bold px-lg-12">
                          {{pro.title}}
                        </v-card-title>
                        <v-card-text class="body-2 navy-text px-lg-12">
                          {{pro.content}}
                        </v-card-text>
                        <v-card-title
                        class="subtitle-1 navy-text font-weight-bold px-lg-12"
                        >
                          ภาพบรรยากาศงาน
                        </v-card-title>
                        <v-card-actions>
                          <v-container>
                            <v-row justify="center">
                              <v-slide-group>
                                <v-slide-item
                                  class="ma-2 img-lan"
                                  v-for="(img, index) in pro.images"
                                  v-bind:key="index"
                                >
                                  <v-img
                                    class="img-lan"
                                    :src="img"
                                  ></v-img>
                                </v-slide-item>
                              </v-slide-group>
                            </v-row>
                          </v-container>
                        </v-card-actions>
                      </v-container>
                    </v-tab-item>
                  </v-tabs>
                </v-card>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" class="py-5">
            <v-btn
              class="bt-bn bt-lan my-5"
              to="/store"
            ><span class="white-text">ไปยัง Store</span></v-btn>
          </v-row>
          <v-row
            class="my-5 font-weight-black headline navy-text ch-white-text"
            justify="center"
          >
            ทำไมถึงต้องเป็นเรา
          </v-row>
          <v-row justify="center" class="">
            <v-slide-group v-model="selectedAboutUs" mandatory>
              <v-slide-item
                v-slot:default="{ active, toggle }"
              >
              <v-card
                height="50px"
                width="50px"
                class="ma-1"
                @click="toggle"
              >
                <Icon01 :isActive="active" />
              </v-card>
              </v-slide-item>
              <v-slide-item
                v-slot:default="{ active, toggle }"
              >
              <v-card
                height="50px"
                width="50px"
                class="ma-1"
                @click="toggle"
              >
                <Icon02 :isActive="active" />
              </v-card>
              </v-slide-item>
              <v-slide-item
                v-slot:default="{ active, toggle }"
              >
              <v-card
                height="50px"
                width="50px"
                class="ma-1"
                @click="toggle"
              >
                <Icon03 :isActive="active" />
              </v-card>
              </v-slide-item>
              <v-slide-item
                v-slot:default="{ active, toggle }"
              >
              <v-card
                height="50px"
                width="50px"
                class="ma-1"
                @click="toggle"
              >
                <Icon04 :isActive="active" />
              </v-card>
              </v-slide-item>
              <v-slide-item
                v-slot:default="{ active, toggle }"
              >
              <v-card
                height="50px"
                width="50px"
                class="ma-1"
                @click="toggle"
              >
                <Icon05 :isActive="active" />
              </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-row>
          <v-row justify="center"
            class="py-4"
          >
            <v-col cols="11" sm="8" md="6" lg="5" xl="4">
              <v-card>
                <v-container class="card-con">
                  <v-row justify="center">
                      <v-img
                        class="icon-lan"
                        :src="aboutUs[selectedAboutUs].icon"></v-img>
                  </v-row>
                  <v-row justify="center" class="text-center">
                      <span class="subtitle-1 font-weight-bold navy-text px-4 py-2 py-sm-4">
                        {{aboutUs[selectedAboutUs].header}}
                      </span>
                  </v-row>
                  <v-row justify="center">
                    <span class="body-2 text-center navy-text mb-4 mx-4 px-4">
                        {{aboutUs[selectedAboutUs].content}}
                    </span>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center" class="py-6">
            <v-btn
              class="bt-bn bt-lan my-5"
              to="/member"
            ><span class="white-text">สมัคร member</span></v-btn>
          </v-row>
          <v-row
            class="px-1 my-5 font-weight-black headline text-center navy-text"
            justify="center"
          >
            เสียงตอบรับจากนักเรียนของเรา
          </v-row>
          <v-row justify="center" class="pa-2 my-5 d-flex d-sm-none">
              <v-window v-model="selectedStudent">
                <v-window-item
                  v-for="(item, index) in students"
                  :key="index"
                >
                  <v-card
                    max-width="300"
                    class="pa-2 ma-2"
                  >
                    <v-container class="px-4">
                      <v-row justify="center">
                        <v-card-text class="navy-text body-2 qou-line font-weight-regular">
                          "{{item.content}}"
                        </v-card-text>
                      </v-row>
                      <v-row>
                        <v-col cols="6"></v-col>
                        <v-col>
                          <v-row class="caption navy-text font-weight-medium font-italic">
                            - {{item.fname}} {{item.lname}}
                          </v-row>
                          <v-row justify="center" class="caption navy-text mx-2 font-weight-medium
                            font-italic">
                            {{item.job}}
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-window-item>
              </v-window>
              <v-card-actions class="justify-space-between">
                <v-btn
                  icon
                  @click="prev"
                >
                  <v-icon>chevron_left</v-icon>
                </v-btn>
                <v-item-group
                  v-model="selectedStudent"
                  class="text-center"
                  mandatory
                >
                  <v-item
                    v-for="n in students.length"
                    :key="`btn-${n}`"
                    v-slot:default="{ active, toggle }"
                  >
                    <v-btn
                      :input-value="active"
                      icon
                      @click="toggle"
                    >
                      <v-icon small>fiber_manual_record</v-icon>
                    </v-btn>
                  </v-item>
                </v-item-group>
                <v-btn
                  icon
                  @click="next"
                >
                  <v-icon>chevron_right</v-icon>
                </v-btn>
              </v-card-actions>
          </v-row>
          <v-row justify="center" class="pa-2 my-5 d-none d-sm-flex">
            <v-slide-group v-model="selectedStudent" mandatory>
              <v-slide-item
                disabled
                v-for="(item, index) in students"
                :key="index"
              >
                <v-card
                  max-width="300"
                  class="pa-2 ma-2"
                >
                  <v-container class="px-4">
                    <v-row justify="center">
                      <v-card-text class="navy-text body-2 qou-line font-weight-regular">
                        "{{item.content}}"
                      </v-card-text>
                    </v-row>
                    <v-row>
                      <v-col cols="6"></v-col>
                      <v-col>
                        <v-row class="caption navy-text font-weight-medium font-italic">
                          - {{item.fname}} {{item.lname}}
                        </v-row>
                        <v-row justify="center" class="caption navy-text mx-2 font-weight-medium
                          font-italic">
                          {{item.job}}
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-row>
          <v-container class="pt-4">
            <v-row
              class="px-1 my-5 font-weight-black headline text-center navy-text"
              justify="center"
            >
              ร่วมพัฒนาไปกับเรา
            </v-row>
            <v-row justify="center">
            <v-container class="navy-text text-center">
              <v-row justify="center" class="subtitle-1 font-weight-bold mb-4 py-md-2">
                หลักสูตร Data Science ที่มีผู้เรียนมากที่สุดในประเทศกว่า 25,000 คน
              </v-row>
              <v-row justify="center" class="body-1 py-sm-2">
                จากบริษัทชั้นนำในประเทศไทยให้การยอมรับ
              </v-row>
              <v-row justify="center" class="body-1 py-sm-2">
                เเละได้รับการรับรองจากจุฬาลงกรณ์มหาวิทยาลัย
              </v-row>
            </v-container>
            </v-row>
            <v-row justify="center" class="pb-lg-4">
              <v-col cols="8">
                <v-row justify="center">
                  <v-col cols="4"
                    v-for="(logo, index) in logos"
                    v-bind:key="index"
                  >
                    <v-row
                      justify="center"
                    >
                      <v-img
                        class="logo-size"
                        :src="logo"
                      ></v-img>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </div>
    </v-lazy>
  </div>
</template>

<script>
import Icon01 from '../components/icons/Icon01.vue';
import Icon02 from '../components/icons/Icon02.vue';
import Icon03 from '../components/icons/Icon03.vue';
import Icon04 from '../components/icons/Icon04.vue';
import Icon05 from '../components/icons/Icon05.vue';

export default {
  name: 'home',
  components: {
    Icon01,
    Icon02,
    Icon03,
    Icon04,
    Icon05,
  },
  data() {
    return {
      proBriefs: [
        {
          header: 'Blended',
          title: 'Blended Learning',
          content: 'เรียนรู้ตลอดชีวิตอย่างมีประสิทธิภาพกว่าที่เคยกับการเรียนแบบ #BlendedLearning ที่ผสมผสาน ระหว่างการเรียนแบบออนไลน์เเละออฟไลน์ เพื่อตอบโจทย์ชีวิตดิจิทัลของทุกคน',
          images: [
            require('../assets/image/blended-01.jpg'),
            require('../assets/image/blended-02.jpg'),
            require('../assets/image/blended-03.jpg'),
            require('../assets/image/blended-04.jpg'),
            require('../assets/image/blended-05.jpg'),
            require('../assets/image/blended-06.jpg'),
            require('../assets/image/blended-07.jpg'),
          ],
        },
        {
          header: 'Pathway',
          title: 'Pathway',
          content: 'หลักสูตรคอร์สเรียนไลน์ในรูปแบบของชุดวิชา (Pathway) ที่จะช่วยพัฒนาผู้เรียนอย่างเป็นระบบ เริ่มจากความรู้พื้นฐานไปถึงการฝึกทักษะให้ประยุกต์ใช้งานได้จริง กระชับ เข้าใจง่าย เหมาะกับคนไทย',
          images: [
            require('../assets/image/pathway-01.jpg'),
            require('../assets/image/pathway-02.jpg'),
            require('../assets/image/pathway-03.jpg'),
            require('../assets/image/pathway-04.jpg'),
            require('../assets/image/pathway-05.jpg'),
            require('../assets/image/pathway-06.jpg'),
            require('../assets/image/pathway-07.jpg'),
          ],
        },
        {
          header: 'Workshop',
          title: 'Workshop',
          content: 'เวิร์คชอปสุดฮิปเเละกิจกรรมเเนวใหม่ที่ไม่เหมือนใคร เต็มไปด้วยเพื่อนๆ อาจารย์ เเละทีมงาน Achieve Plus ที่จะอยู่เคียงข้าง พร้อมผลักดันให้คุณก้าวไปอีกขั้นเสมอ',
          images: [
            require('../assets/image/lan-workshop-01.jpg'),
            require('../assets/image/lan-workshop-02.jpg'),
            require('../assets/image/lan-workshop-03.jpg'),
            require('../assets/image/lan-workshop-04.jpg'),
            require('../assets/image/lan-workshop-05.jpg'),
            require('../assets/image/lan-workshop-06.jpg'),
            require('../assets/image/lan-workshop-07.jpg'),
          ],
        },
      ],
      aboutUs: [
        {
          icon: require('../assets/image/icon-20.svg'),
          header: 'บริการออกเเบบคอร์สเรียน',
          content: 'เราสามารถจัดคอร์สเรียนหรือเวิร์กชอปที่เหมาะสมกับความต้องการให้กับองค์กรของคุณได้ พร้อมบริการ Special Clinic กิจกรรมเสริมสร้างความเข้าใจ เพื่อเป็นเเนวทางในการสำเร็จคอร์สเรียน',
        },
        {
          icon: require('../assets/image/icon-21.svg'),
          header: 'ส่งต่อประสบการณ์ สอนกระชับ เข้าใจง่าย',
          content: 'เราคัดสรรอาจารย์ทรงคุณวุฒิมากประสบการณ์ จากจุฬาลงกรณ์มหาวิทยาลัยเเละสถาบันชั้นนำต่างๆ ที่มีเทคนิคการสอนเฉพาะตัว เพื่อทำให้คุณได้รับประสบการณ์การเรียนรู้ที่ดีที่สุด เเละสามารถนำไปปรับใช้ในชีวิตจริงได้',
        },
        {
          icon: require('../assets/image/icon-22.svg'),
          header: 'เรียนได้ทุกที่ ทุกเวลา ไม่สะดุด',
          content: 'รูปแบบการเรียนผ่านคอมพิวเตอร์และสมาร์ทโฟน สนุกสนาน น่าติดตาม สะดวก ไม่สะดุด ระบบมีเสถียรภาพกว่า 99% ให้คุณบริหารเวลาในการเรียนตามไลฟ์สไตล์ของคุณ',
        },
        {
          icon: require('../assets/image/icon-23.svg'),
          header: 'สร้างสังคมการเรียนรู้ ไม่ต้องเรียนคนเดียว',
          content: 'ผู้เรียนสามารถแสดงความคิดเห็นเเละแชร์ความรู้ร่วมกันผ่าน Facebook Group โดยมีอาจารย์ผู้ช่วย Teaching Assistant และ Pathway Assistant คอยตอบข้อสงสัย และคอยให้คำปรึกษาอยู่เสมอ',
        },
        {
          icon: require('../assets/image/icon-24.svg'),
          header: 'คุ้มค่า ต่อยอดอาชีพ ก้าวสู่ความสำเร็จไปด้วยกัน',
          content: 'เนื้อหาทั้งหมดเป็นภาษาไทย ที่คนไทยสามารถเข้าใจได้ทันที พร้อมใบประกาศนียบัตรยืนยันความสำเร็จของคุณจาก Achieve Plus เมื่อเรียนจบ',
        },
      ],
      students: [
        {
          content: 'ได้ความรู้มากเลยครับ ช่วยเปิดมุมมองในด้าน Data Science รวมถึงการใช้ Software ต่างๆ ในการวิเคราะห์ มีการปูพื้นฐานค่อนข้างครบถ้วน',
          fname: 'วสุ',
          lname: 'สามารถสิริ',
          image: '',
          job: 'วิศวกร',
        },
        {
          content: 'น่าสนใจ เเล้วก็ราคาไม่เเพงมาก ไม่มีเวลาก็เรียนออนไลน์ได้',
          fname: 'ณภัทร',
          lname: 'ปิ่นทอง',
          image: '',
          job: 'Business Development',
        },
        {
          content: 'มีอาจารย์จุฬาฯ มาสอน เพราะฉะนั้นก็จะมีความน่าเชื่อถือ',
          fname: 'นนท์',
          lname: 'สายพัฒนา',
          image: '',
          job: 'นักกายภาพบำบัด',
        },
        {
          content: 'การันตีได้ว่า ให้ความรู้เยอะ จากที่ไม่เข้าใจเลยว่าเอามาใช้ทำอะไร ก็ทำให้รู้ว่าจะปรับใช้กับชีวิตยังไงบ้าง',
          fname: 'ภีมเดช',
          lname: 'เนียมวงษ์',
          image: '',
          job: 'วิศวกรโยธา',
        },
        {
          content: 'สามารถนำข้อมูลจาก Customer Insight เพื่อนำไปพัฒนา Product ต่างๆ ได้',
          fname: 'ณัชชา',
          lname: 'วรเศรษฐ์',
          image: '',
          job: 'Business Development',
        },
      ],
      logos: [
        require('../assets/image/logo-01-wd.png'),
        require('../assets/image/logo-03-kbank.png'),
        require('../assets/image/logo-02-cpram.png'),
        require('../assets/image/logo-04-ghbank.png'),
        require('../assets/image/logo-05-mea.png'),
        require('../assets/image/logo-06-krubank.png'),
        require('../assets/image/logo-07-scb.png'),
      ],
      bannerClass: ['container-banner', 'studio-banner'],
      bannerLength: 2,
      selectedBanner: 0,
      selectedProBriefs: 0,
      selectedAboutUs: 0,
      selectedStudent: 0,
      lazyOption: {
        threshold: 0.5,
      },
    };
  },
  head() {
    const descriptionSEO = 'หลักสูตร Digital Skills (Data, IT, Media, Communication, Technology) ที่กระชับที่สุด เหมาะกับคนไทยที่สุด เข้าใจง่ายที่สุด นำไปใช้งานได้จริง ในรูปแบบ "Blended Learning" (Online ผสม Offline) โดยทีมงาน Achieve พร้อมผลักดันผู้เรียนของเราให้ก้าวไปอีกขั้นอยู่เสมอ';
    return {
      meta: [
        { name: 'description', content: descriptionSEO },
        { property: 'og:url', content: 'https://www.achieve.plus' },
        { property: 'og:title', content: 'Home' },
        { property: 'og:description', content: descriptionSEO },
        // { property: 'og:image', content: 'https://www.achieve.plus/img/icons/favicon192.png' },
        // { property: 'og:image:width', content: '1200' },
        // { property: 'og:image:height', content: '630' },
        { name: 'twitter:title', content: 'Home' },
        { name: 'twitter:site', content: 'https://www.achieve.plus' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:description', content: descriptionSEO },
        { itemprop: 'name', content: 'Home' },
        { itemprop: 'description', content: descriptionSEO },
      ],
    };
  },
  methods: {
    next() {
      this.selectedStudent = this.selectedStudent + 1 === this.students.length
        ? 0
        : this.selectedStudent + 1;
    },
    prev() {
      this.selectedStudent = this.selectedStudent - 1 < 0
        ? this.this.students.length - 1
        : this.selectedStudent - 1;
    },
    goStudio() {
      console.log('click 2');
      this.$router.push('/studio');
    },
  },
};
</script>

<style lang="sass" scoped>
.container-banner
  height: 255px
  background-repeat: no-repeat
  background-size: 100%
  background-position: center
  max-height: 255px
  @media screen and (min-width: 450px)
    height: 370px
    max-height: 370px
    background-size: auto
  @media screen and (min-width: 1060px)
    height: 420px
    max-height: 420px
    background-size: 120%
  @media screen and (min-width: 1264px)
    background-size: 100%

.bn1-bg
  background-image: url('../assets/image/bg-section-4-xs.jpg')
  @media screen and (min-width: 600px)
    background-image: url('../assets/image/bg-section-4-md.jpg')
  @media screen and (min-width: 1060px)
    background-image: url('../assets/image/bg-section-4-lg.jpg')

.bn2-bg
  // background-color: #003A70
  background-image: url('../assets/image/achieveStudioBanner-01.jpg')
  @media screen and (min-width: 321px)
     background-size: 105%
     background-image: url('../assets/image/achieveStudioBanner-02.jpg')
  @media screen and (min-width: 420px)
    background-size: 95%
  @media screen and (min-width: 990px)
    background-size: 100%
    background-image: url('../assets/image/achieveStudioBanner-03.jpg')


.window-banner
  height: 200px
  @media screen and (min-width: 450px)
    height: 310px
  @media screen and (min-width: 1060px)
    height: 350px
  @media screen and (min-width: 1264px)
    height: 350px

.container-bg
  height: auto
  background-repeat: no-repeat
  background-image: url('../assets/image/langing01.png')
  background-size: 110%
  @media screen and (min-width: 321px)
    background-size: 117%
  @media screen and (min-width: 445px)
    background-image: url('../assets/image/langing02.png')
    background-position: center
    background-size: auto
  @media screen and (min-width: 600px) and (max-width: 1263px)
    background-position: center
    background-size: 100%
    background-image: url('../assets/image/langing03.png')
  @media screen and  (min-width: 1264px)
    background-position: center
    background-size: 100%
    background-image: url('../assets/image/langing04.png')
.icon-lan
  max-height: 75px
  max-width: 75px
  @media screen and (min-width: 450px) and (max-width: 770px)
    max-height: 90px
    max-width: 90px
  @media screen and ( min-width:  770px)
    max-height: 105px
    max-width: 105px

.ch-white-text
  @media screen and (min-width: 350px)
    color: #FFF9FB

.logo-size
  max-height: auto
  max-width: auto
  @media screen and ( min-width:  450px)
    max-height: 40px
    max-width: 120px
.img-lan
  height: 100px
  width: 100px
  max-height: 100px
  max-width: 100px
  @media screen and (min-width: 600px)
    height: 210px
    width: 240px
    max-height: 210px
    max-width: 240px
.select-tab
  background: #003A70
.qou-line
  @media screen and ( min-width:  600px)
    line-height: 1.5rem !important
.bt-bn
  background: linear-gradient(142.12deg, #F5333F 0%, #BB004B 100%)
  color: #FFF9FB
.card-con
  width: auto
  height: auto
  border-radius: 10px
  background: linear-gradient(108.13deg, #FFFFFF 0%, #EDF1FE 98.43%)
</style>
