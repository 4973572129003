<template>
<!-- eslint-disable max-len  -->
 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 75 75">
        <defs>
        <linearGradient id="linear-gradient" x1="-0.83" y1="-0.54" x2="76.5" y2="76.21" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#d7362c"/>
            <stop offset="1" stop-color="#c31e2f"/>
        </linearGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
        <rect :class="classBackground" width="75" height="75" />
    </g>
    <path :class="classBlue" d="M20,60.79a1,1,0,0,0,.95.71H37.57a1,1,0,0,0,0-2H21.68L17.94,46.92a1.27,1.27,0,0,1,1.22-1.64H55.84a1.27,1.27,0,0,1,1.22,1.64l-4,13.3a1,1,0,0,0,1.91.57l4-13.3a3.29,3.29,0,0,0-3.14-4.21h-5.1V38.06a1,1,0,0,0-.35-.75l-6-5.15a1,1,0,1,0-1.31,1.51l5.62,4.85v4.76H26.26V38.51l5.63-5A1,1,0,0,0,30.56,32l-6,5.3a1,1,0,0,0-.34.74v5.22h-5.1A3.29,3.29,0,0,0,16,47.49Z"/>
    <path :class="classBlue" d="M61.86,61.28l-2.31-4.34a1,1,0,1,0-1.76.94l2.3,4.34a.86.86,0,0,1,0,.88.89.89,0,0,1-.77.44H15.7a.89.89,0,0,1-.77-.44.86.86,0,0,1,0-.88l2.3-4.34a1,1,0,1,0-1.76-.94l-2.31,4.34a2.91,2.91,0,0,0,2.56,4.26H59.3a2.91,2.91,0,0,0,2.56-4.26Z"/>
    <path :class="classBlue" d="M28.15,26.45h1.34c.7,4.48,4.06,7.88,8.08,7.88s7.39-3.4,8.09-7.88h1.19A3.19,3.19,0,0,0,50,23.26V22.08a3.19,3.19,0,0,0-2-3,10.52,10.52,0,0,0-21,0,3.19,3.19,0,0,0-2.05,3v1.18A3.19,3.19,0,0,0,28.15,26.45Zm9.42,5.88c-3.43,0-6.22-3.41-6.22-7.61s2.79-7.62,6.22-7.62,6.23,3.42,6.23,7.62S41,32.33,37.57,32.33ZM48,23.26a1.19,1.19,0,0,1-1.19,1.19H45.79a10.88,10.88,0,0,0-.68-3.56h1.64a1.26,1.26,0,0,0,.28.05h0a1.19,1.19,0,0,1,1,1.15ZM37.5,11.41A8.55,8.55,0,0,1,46,18.89H44.1a7.88,7.88,0,0,0-6.53-3.79,7.85,7.85,0,0,0-6.52,3.79H29A8.55,8.55,0,0,1,37.5,11.41ZM27,22.08a1.19,1.19,0,0,1,1-1.15h0a1.47,1.47,0,0,0,.29-.05H30a10.88,10.88,0,0,0-.67,3.56H28.15A1.19,1.19,0,0,1,27,23.26Z"/>
    <polygon :class="classRed" points="55.87 46.56 55.85 46.53 37.5 46.53 19.16 46.53 20.12 49.88 54.88 49.88 55.87 46.56" />
</svg>
</template>

<script>
export default {
  name: 'icon',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classBlue() {
      return this.isActive ? 'color-active-white' : 'color-blue';
    },
    classRed() {
      return this.isActive ? 'color-active-white' : 'color-red';
    },
    classBackground() {
      return this.isActive ? 'color-active' : 'hidden';
    },
  },
};
</script>

<style scoped>
.color-active {
   fill:url(#linear-gradient);
}
.color-active-white {
  fill:#fff;
}
.color-blue {
  fill: #003a70;
}
.color-red {
  fill: #f5333f;
}
.hidden {
  visibility: hidden;
}
</style>
